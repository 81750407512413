var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar", { attrs: { stageTitle: "Refer a Friend!" } }),
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "container-wrapper" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-container-right" }, [
            _c("div", { staticClass: "div-earnings" }, [
              _c("div", { staticClass: "user-earning" }, [
                _c("div", { staticClass: "amount-header" }, [
                  _vm._v("You’ve earned"),
                ]),
                _vm.referralStatistics
                  ? _c("div", { staticClass: "amount-value" }, [
                      _vm._v(
                        "\n              $" +
                          _vm._s(_vm.referralStatistics.total_earnings) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "user-claimed" }, [
                _c("div", { staticClass: "amount-header" }, [
                  _vm._v("You’ve claimed"),
                ]),
                _vm.referralStatistics
                  ? _c("div", { staticClass: "amount-value" }, [
                      _vm._v(
                        "\n              $\n              " +
                          _vm._s(_vm.referralStatistics.claimed) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "user-earning-left" }, [
                _c("div", { staticClass: "amount-header" }, [
                  _vm._v("You still have"),
                ]),
                _vm.referralStatistics
                  ? _c("div", { staticClass: "amount-value" }, [
                      _vm._v(
                        "\n              $" +
                          _vm._s(_vm.referralStatistics.unclaimed) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "divider-line" }),
            _c("div", { staticClass: "referral-count" }, [
              _vm._m(1),
              _vm.referralStatistics
                ? _c("div", { staticClass: "total-founders" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.referralStatistics.founders_count) +
                        "\n            Founders\n          "
                    ),
                  ])
                : _vm._e(),
              _vm.referralStatistics
                ? _c("div", { staticClass: "total-experts" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.referralStatistics.experts_count) +
                        "\n            Experts\n          "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "nominate-container" }, [
          _c("div", { staticClass: "centered-container" }, [
            _c("div", { staticClass: "wrapper-tabs" }, [
              _c(
                "div",
                {
                  staticClass: "referral-tab",
                  class: { "active-tab": _vm.invitationType == "founder" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSwitchTab("founder")
                    },
                  },
                },
                [_vm._v("\n            Refer a Founder\n          ")]
              ),
              _c(
                "div",
                {
                  staticClass: "referral-tab",
                  class: { "active-tab": _vm.invitationType == "expert" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSwitchTab("expert")
                    },
                  },
                },
                [_vm._v("\n            Refer a FAANG Expert\n          ")]
              ),
              _c(
                "div",
                {
                  staticClass: "referral-tab",
                  class: { "active-tab": _vm.invitationType == "nominate_vc" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSwitchTab("nominate_vc")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            Nominate\n            " +
                      _vm._s(_vm.currentUser.user_type == 1 ? "a" : "your") +
                      "\n            VC\n          "
                  ),
                ]
              ),
            ]),
            _vm.invitationType !== "nominate_vc"
              ? _c("div", { staticClass: "founder-expert-container" }, [
                  _c("div", { staticClass: "founder-container" }, [
                    _c("div", { staticClass: "founder-profit" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.invitationType == "founder"
                              ? "Refer a Founder and get up to $1,250"
                              : "Refer a FAANMG Expert and get $1,000"
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm.invitationType == "founder"
                      ? _c("div", { staticClass: "bottom-text" }, [
                          _vm._v("\n              You get\n              "),
                          _c("span", [_vm._v("$250")]),
                          _vm._v(
                            "\n              once they reserve a team and another\n              "
                          ),
                          _c("span", [_vm._v("$1,000")]),
                          _vm._v(
                            " once they start their first project on Grupa\n            "
                          ),
                        ])
                      : _c("div", { staticClass: "bottom-text" }, [
                          _vm._v("\n              You get "),
                          _c("span", [_vm._v("$1,000")]),
                          _vm._v(
                            " once they form a team and start\n              their first project on Grupa\n            "
                          ),
                        ]),
                  ]),
                  _c("div", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n            Enter the email address(es) of your friend(s) you wish to invite\n          "
                    ),
                  ]),
                  _c("div", { staticClass: "form-error" }, [
                    _vm.formError.expert_email
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formError.expert_email) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "form-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.expert_email,
                          expression: "form.expert_email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "Email address", type: "email" },
                      domProps: { value: _vm.form.expert_email },
                      on: {
                        blur: function ($event) {
                          return _vm.handleEmailValidation("expert_email")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "expert_email",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "grupa-blue-btn",
                        staticStyle: { width: "115px" },
                        attrs: { disabled: _vm.spinner.expert_email },
                        on: {
                          click: function ($event) {
                            return _vm.handleSendInvite("expert_email")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              !_vm.spinner.expert_email ? "Send Invite" : ""
                            ) +
                            "\n              "
                        ),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.spinner.expert_email,
                              expression: "spinner.expert_email",
                            },
                          ],
                          staticClass: "button-spinner",
                          attrs: { src: "/img/lightbox/preloader.gif" },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "divider-line" }),
                  _c("div", { staticClass: "referral-link-container" }, [
                    _c("div", { staticClass: "form-label" }, [
                      _vm._v(
                        "\n              Click to copy or share your\n              " +
                          _vm._s(
                            _vm.invitationType == "founder"
                              ? "founders"
                              : "experts"
                          ) +
                          "’\n              referral link on your social media\n            "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "copy-link-form",
                        on: { click: _vm.handleCopyLink },
                      },
                      [
                        _c("input", {
                          staticClass: "form-control dark",
                          attrs: {
                            disabled: "",
                            id: "userReferralLink",
                            type: "text",
                          },
                          domProps: {
                            value:
                              _vm.$referralUrl + "?ref=" + _vm.userReferralCode,
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.copyTextValue))]),
                      ]
                    ),
                    _c("div", { staticClass: "social-media-link" }, [
                      _c("div", {
                        staticClass: "addthis_inline_share_toolbox",
                        attrs: {
                          "data-url":
                            _vm.$referralUrl + "?ref=" + _vm.userReferralCode,
                          "data-title":
                            "Join Grupa via " + _vm.currentUser.name,
                          "data-description":
                            "We provide plug-and-play product teams for startups. Come aboard today",
                        },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.invitationType === "nominate_vc"
              ? _c("div", { staticClass: "nominating-container" }, [
                  _c("div", { staticClass: "vc-nominate" }, [
                    _c("div", { staticClass: "nominate-msg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.currentUser.user_type == 1
                              ? "Nominate a VC and earn Grupa credits: 15% more on earnings"
                              : "Nominate your VC to get 15% off on checkout"
                          ) +
                          "\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "nominate-description" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.currentUser.user_type == 1
                              ? "Nominate your Friends who are Investors who may need Grupa for their portfolio companies to join the Grupa VC discount program. They get a unique 15% discount code for their founders  and you earn 15% more on your Grupa earnings + Grupa Credits"
                              : "Nominate your VC to participate in Grupa’s discount program. Your VC\n            gets a unique 15% discount code that every founder in their\n            network can use on checkout."
                          ) +
                          "\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "vc-details-form" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vc_name,
                            expression: "form.vc_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Firstname e.g Marc",
                          type: "text",
                        },
                        domProps: { value: _vm.form.vc_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "vc_name", $event.target.value)
                          },
                        },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vcContactName,
                            expression: "form.vcContactName",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "VC e.g. a16z", type: "text" },
                        domProps: { value: _vm.form.vcContactName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "vcContactName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "invite-message-container" }, [
                      _c("div", { staticClass: "edit-pencil" }, [
                        _c("div", { on: { click: _vm.handleEditPen } }, [
                          _c("img", {
                            attrs: {
                              src: "/img/dashboard/edit-pencil.svg",
                              alt: "",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "vc-msg-error" }, [
                          _vm.vcMessageError
                            ? _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.vcMessageError) +
                                    "\n                  "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "invite-message",
                          attrs: {
                            contentEditable: "true",
                            id: "messageWrapper",
                          },
                        },
                        [
                          _vm._v("\n                Hey ,\n                "),
                          _c(
                            "strong",
                            {
                              attrs: { contentEditable: "true", id: "vcName" },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.form.vc_name
                                      ? _vm.form.vc_name
                                      : "VC name"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                I have been on Grupa working with elite startups on their\n                products. I think founders in your\n                "
                          ),
                          _c(
                            "strong",
                            {
                              attrs: {
                                contentEditable: "true",
                                id: "vcContactName",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.form.vcContactName
                                      ? _vm.form.vcContactName
                                      : "VC contact name"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                portfolio will find it useful working with FAANG teams for\n                cash + equity to help them get to product market fit.\n                "
                          ),
                          _c("div", { staticClass: "check-message" }, [
                            _vm._v(
                              "\n                  You should check it out and connect with the Grupa team\n                "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-error" }, [
                      _vm.formError.vc_email
                        ? _c("span", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.formError.vc_email) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "vc-form-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.vc_email,
                            expression: "form.vc_email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Enter VC email address",
                          type: "email",
                        },
                        domProps: { value: _vm.form.vc_email },
                        on: {
                          blur: function ($event) {
                            return _vm.handleEmailValidation("vc_email")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "vc_email", $event.target.value)
                          },
                        },
                      }),
                      _c("div", { staticClass: "vc-btn-wrapper" }, [
                        _c(
                          "button",
                          {
                            staticClass: "grupa-blue-btn",
                            on: { click: _vm.handleVCMail },
                          },
                          [
                            _vm._v(
                              "\n                  Send Invite\n                  "
                            ),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.spinner.vc_email,
                                  expression: "spinner.vc_email",
                                },
                              ],
                              staticClass: "button-spinner",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { src: "/img/lightbox/preloader.gif" },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-container-left" }, [
      _c("div", { staticClass: "message-wrapper" }, [
        _c("div", { staticClass: "earn-message" }, [
          _vm._v("Refer and Earn up to $1,250!"),
        ]),
      ]),
      _c("div", { staticClass: "referral-icon" }, [
        _c("img", {
          attrs: { src: "/img/dashboard/referral-image.svg", alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "count-msg" }, [
      _c("img", {
        attrs: { src: "/img/dashboard/referral-icon.svg", alt: "" },
      }),
      _c("div", [_vm._v("You have sucessfully referred")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }