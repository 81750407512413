<template>
  <div>
    <Navbar stageTitle="Refer a Friend!" />
    <div class="page-container">
      <div class="container-wrapper">
        <div class="card-container-left">
          <div class="message-wrapper">
            <div class="earn-message">Refer and Earn up to $1,250!</div>
          </div>
          <div class="referral-icon">
            <img src="/img/dashboard/referral-image.svg" alt="" />
          </div>
        </div>
        <div class="card-container-right">
          <div class="div-earnings">
            <div class="user-earning">
              <div class="amount-header">You’ve earned</div>
              <div class="amount-value" v-if="referralStatistics">
                ${{ referralStatistics.total_earnings }}
              </div>
            </div>
            <div class="user-claimed">
              <div class="amount-header">You’ve claimed</div>
              <div class="amount-value" v-if="referralStatistics">
                $
                {{ referralStatistics.claimed }}
              </div>
            </div>
            <div class="user-earning-left">
              <div class="amount-header">You still have</div>
              <div class="amount-value" v-if="referralStatistics">
                ${{ referralStatistics.unclaimed }}
              </div>
            </div>
          </div>
          <div class="divider-line"></div>
          <div class="referral-count">
            <div class="count-msg">
              <img src="/img/dashboard/referral-icon.svg" alt="" />
              <div>You have sucessfully referred</div>
            </div>
            <div v-if="referralStatistics" class="total-founders">
              {{ referralStatistics.founders_count }}
              Founders
            </div>
            <div v-if="referralStatistics" class="total-experts">
              {{ referralStatistics.experts_count }}
              Experts
            </div>
          </div>
        </div>
      </div>
      <div class="nominate-container">
        <div class="centered-container">
          <div class="wrapper-tabs">
            <div
              :class="{ 'active-tab': invitationType == 'founder' }"
              class="referral-tab"
              @click="handleSwitchTab('founder')"
            >
              Refer a Founder
            </div>
            <div
              class="referral-tab"
              :class="{ 'active-tab': invitationType == 'expert' }"
              @click="handleSwitchTab('expert')"
            >
              Refer a FAANG Expert
            </div>
            <div
              class="referral-tab"
              :class="{ 'active-tab': invitationType == 'nominate_vc' }"
              @click="handleSwitchTab('nominate_vc')"
            >
              Nominate
              {{ currentUser.user_type == 1 ? "a" : "your" }}
              VC
            </div>
          </div>
          <div
            v-if="invitationType !== 'nominate_vc'"
            class="founder-expert-container"
          >
            <div class="founder-container">
              <div class="founder-profit">
                {{
                  invitationType == "founder"
                    ? `Refer a Founder and get up to $1,250`
                    : "Refer a FAANMG Expert and get $1,000"
                }}
              </div>
              <div class="bottom-text" v-if="invitationType == `founder`">
                You get
                <span>$250</span>
                once they reserve a team and another
                <span>$1,000</span> once they start their first project on Grupa
              </div>
              <div v-else class="bottom-text">
                You get <span>$1,000</span> once they form a team and start
                their first project on Grupa
              </div>
            </div>
            <div class="form-label">
              Enter the email address(es) of your friend(s) you wish to invite
            </div>
            <div class="form-error">
              <span v-if="formError.expert_email">
                {{ formError.expert_email }}
              </span>
            </div>
            <div class="form-container">
              <input
                class="form-control"
                placeholder="Email address"
                type="email"
                v-model="form.expert_email"
                @blur="handleEmailValidation('expert_email')"
              />
              <button
                @click="handleSendInvite('expert_email')"
                class="grupa-blue-btn"
                style="width: 115px"
                :disabled="spinner.expert_email"
              >
                {{ !spinner.expert_email ? "Send Invite" : "" }}
                <img
                  src="/img/lightbox/preloader.gif"
                  class="button-spinner"
                  v-show="spinner.expert_email"
                />
              </button>
            </div>
            <div class="divider-line"></div>
            <div class="referral-link-container">
              <div class="form-label">
                Click to copy or share your
                {{ invitationType == "founder" ? "founders" : "experts" }}’
                referral link on your social media
              </div>
              <div class="copy-link-form" @click="handleCopyLink">
                <input
                  class="form-control dark"
                  disabled
                  id="userReferralLink"
                  type="text"
                  :value="$referralUrl + '?ref=' + userReferralCode"
                />
                <span>{{ copyTextValue }}</span>
              </div>
              <div class="social-media-link">
                <div
                  class="addthis_inline_share_toolbox"
                  :data-url="`${$referralUrl}?ref=${userReferralCode}`"
                  :data-title="`Join Grupa via ${currentUser.name}`"
                  data-description="We provide plug-and-play product teams for startups. Come aboard today"
                ></div>
              </div>
            </div>
          </div>
          <div
            v-if="invitationType === 'nominate_vc'"
            class="nominating-container"
          >
            <div class="vc-nominate">
              <div class="nominate-msg">
                {{
                  currentUser.user_type == 1
                    ? `Nominate a VC and earn Grupa credits: 15% more on earnings`
                    : `Nominate your VC to get 15% off on checkout`
                }}
              </div>
              <div class="nominate-description">
                {{
                  currentUser.user_type == 1
                    ? `Nominate your Friends who are Investors who may need Grupa for their portfolio companies to join the Grupa VC discount program. They get a unique 15% discount code for their founders  and you earn 15% more on your Grupa earnings + Grupa Credits`
                    : `Nominate your VC to participate in Grupa’s discount program. Your VC
              gets a unique 15% discount code that every founder in their
              network can use on checkout.`
                }}
              </div>
              <div class="vc-details-form">
                <input
                  class="form-control"
                  v-model="form.vc_name"
                  placeholder="Firstname e.g Marc"
                  type="text"
                />
                <input
                  class="form-control"
                  v-model="form.vcContactName"
                  placeholder="VC e.g. a16z"
                  type="text"
                />
              </div>
              <div class="invite-message-container">
                <div class="edit-pencil">
                  <div @click="handleEditPen">
                    <img src="/img/dashboard/edit-pencil.svg" alt="" />
                  </div>
                  <div class="vc-msg-error">
                    <span v-if="vcMessageError">
                      {{ vcMessageError }}
                    </span>
                  </div>
                </div>

                <div
                  class="invite-message"
                  contentEditable="true"
                  id="messageWrapper"
                >
                  Hey ,
                  <strong contentEditable="true" id="vcName">
                    {{ form.vc_name ? form.vc_name : "VC name" }}
                  </strong>
                  I have been on Grupa working with elite startups on their
                  products. I think founders in your
                  <strong contentEditable="true" id="vcContactName">
                    {{
                      form.vcContactName
                        ? form.vcContactName
                        : "VC contact name"
                    }}
                  </strong>
                  portfolio will find it useful working with FAANG teams for
                  cash + equity to help them get to product market fit.
                  <div class="check-message">
                    You should check it out and connect with the Grupa team
                  </div>
                </div>
              </div>
              <div class="form-error">
                <span v-if="formError.vc_email">
                  {{ formError.vc_email }}
                </span>
              </div>
              <div class="vc-form-container">
                <input
                  class="form-control"
                  placeholder="Enter VC email address"
                  type="email"
                  v-model="form.vc_email"
                  @blur="handleEmailValidation('vc_email')"
                />
                <div class="vc-btn-wrapper">
                  <button @click="handleVCMail" class="grupa-blue-btn">
                    Send Invite
                    <img
                      src="/img/lightbox/preloader.gif"
                      style="margin-left: 20px"
                      class="button-spinner"
                      v-show="spinner.vc_email"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>
  <script>
import Navbar from "@/components/general/toolbar";
import { getUserReferral, sendReferralEmail } from "@/api";
import { validateEmail } from "@/utils/validationHelpers";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import { mapActions, mapState } from "vuex";
import mixPanel from "@/mixins/mixPanel";

export default {
  mixins: [mixPanel],
  props: {},
  components: {
    Navbar,
    AlertSuccess,
    AlertError,
  },

  data: () => ({
    form: {
      expert_email: "",
      vc_email: "",
      vc_name: "",
      vcContactName: "",
    },
    formError: {
      expert_email: "",
      vc_email: "",
    },
    spinner: {
      expert_email: false,
      vc_email: false,
    },
    referralCodeFounder: "user",
    referralCodeExpert: "user",
    user: null,
    userReferrals: [],
    copyTextValue: "Copy",
    vcMessageError: "",
    referralStatistics: null,
    invitationType: "founder",
  }),
  watch: {},
  created() {
    this.setUserReferralCode();
    this.fetchUserReferral();
  },
  updated() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6140ce500b188035"
    );
    document.head.appendChild(externalScript);
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    fetchUserReferral() {
      getUserReferral()
        .then((response) => {
          if (response.data.status == 1) {
            const { referralCodeFounder, referralCode } = response.data.data;
            this.referralCodeFounder = referralCodeFounder;
            this.referralCodeExpert = referralCode;

            this.referralStatistics = response.data.data;
          }
        })
        .catch((error) => {});
    },
    handleSendInvite(type) {
      let email = this.form[`${type}`];
      const hasError = validateEmail(email);
      this.formError.vc_email = hasError;
      if (!hasError) {
        let payload = [];
        let emailObj = { email };
        payload.push(emailObj);
        this.spinner[`${type}`] = true;
        let inviteType;
        let referedUser;
        if (this.invitationType == "founder") {
          inviteType = 2;
          referedUser = "ReferFounder";
        } else {
          inviteType = 1;
          referedUser = "ReferExpert";
        }
        sendReferralEmail(inviteType, payload)
          .then((response) => {
            if (response.data.status == 1) {
              this.setSuccessMsg("Mail sent successfully");
              this.mixPanelTrack({
                event: referedUser,
                email: this.currentUser.email,
                message: "Successful",
                vcEmail: this.form[`${type}`],
              });

              this.form[`${type}`] = "";
              this.form.vc_name = "";
              this.form.vcContactName = "";
            }
          })
          .catch((error) => {
            this.setErrorMsg("Error sending mail");
          })
          .finally(() => {
            this.spinner[`${type}`] = false;
          });
      }
    },
    handleVCMail() {
      let mainMessage = document.getElementById("messageWrapper").textContent;
      let vcContactName = document.getElementById("vcContactName");
      let vcName = document.getElementById("vcName");
      //  Validation for VC Contact Name field
      if (vcContactName) {
        if (
          vcContactName.textContent &&
          vcContactName.textContent == "VC contact name"
        ) {
          this.vcMessageError = "Enter VC Contact Name";
          return;
        }
      }
      //  Validation for VC Name field
      if (vcName) {
        if (vcName.textContent && vcName.textContent == "VC name") {
          this.vcMessageError = "Enter VC Name";
          return;
        }
      }
      this.vcMessageError = "";
      let email = this.form.vc_email;
      const hasError = validateEmail(email);
      this.formError.vc_email = hasError;
      if (!hasError) {
        let payload = [];
        let emailObj = { email, text: mainMessage };
        payload.push(emailObj);
        this.spinner.vc_email = true;
        let inviteType = 3;
        sendReferralEmail(inviteType, payload)
          .then((response) => {
            if (response.data.status == 1) {
              this.setSuccessMsg("Mail sent successfully");
              this.mixPanelTrack({
                event: "ReferVC",
                email: this.currentUser.email,
                message: "Successful",
                vcEmail: this.form.vc_email,
              });
              this.form.vc_email = "";
            }
          })
          .catch((error) => {
            this.setErrorMsg("Error sending mail");
          })
          .finally(() => {
            this.spinner.vc_email = false;
          });
      }
    },
    handleEmailValidation(type) {
      let email = this.form[`${type}`];
      this.formError[`${type}`] = validateEmail(email);
    },
    handleCopyLink() {
      let element = document.getElementById("userReferralLink");
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(element.value)
          .then(() => {
            this.copyTextValue = "Copied";
            setTimeout(() => {
              this.copyTextValue = "Copy";
            }, 4000);
          })
          .catch((err) => {});
      }
    },
    handleEditPen() {
      document.getElementById("messageWrapper").focus();
    },
    setUserReferralCode() {
      const { referralCodeFounder, referralCode, user_type } = this.currentUser;
      if (referralCodeFounder) {
        this.referralCodeFounder = referralCodeFounder;
        this.referralCodeExpert = referralCode;
      }
      let defaultUser = user_type == 1 ? "expert" : "founder";
      this.invitationType = defaultUser;
    },
    handleSwitchTab(type) {
      this.invitationType = type;
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("userManagement", ["currentUser"]),
    changePlaceholderColor() {
      let vcContactName = document.getElementById("vcContactName");
      if (vcContactName && vcContactName.textContent == "VC contact name") {
        return "text-grey-color";
      } else {
        return "text-black-color";
      }
    },
    userReferralCode() {
      if (this.invitationType == "founder") {
        return this.referralCodeFounder;
      } else {
        return this.referralCodeExpert;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.container-wrapper {
  padding: 24px;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  text-align: left;
}
.card-container-left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.earn-message {
  font-size: 18px;
  line-height: 145%;
  display: flex;
  color: #1b1e22;
  width: 175px;
}
.card-container-right {
  width: 50%;
}
.white-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  width: 50%;
  text-align: left;
}
.faang-message {
  font-size: 18px;
  line-height: 22px;
  display: flex;
  color: #1b1e22;
  margin-top: 12px;
}
.referral-message {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 24px;
}
.bottom-text span,
.referral-message span {
  color: #1b1e22;
}
.div-earnings {
  display: flex;
}
.user-claimed,
.user-earning {
  margin-right: 64px;
}
.amount-header {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.amount-value {
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  color: #979da5;
}
.user-earning-left .amount-value {
  color: #000000;
}
.divider-line {
  border-top: 1px solid #e4e5e7;
  width: 218px;
  margin-top: 48px;
}
.nominate-container .divider-line {
  width: initial;
}
.referral-count {
  margin-top: 24px;
}
.count-msg {
  display: flex;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.total-founders,
.total-experts {
  margin-top: 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}

.form-wrapper {
  padding: 12px;
  display: flex;
  background: #ffffff;
  margin-top: 16px;
}
.form-card {
  padding: 12px;
  width: 50%;
  text-align: left;
}
.form-wrapper .form-card:nth-of-type(1) {
  border-right: 1px solid #e4e5e7;
  padding-right: 64px;
}
.form-wrapper .form-card:nth-of-type(2) {
  padding-left: 24px;
}
.form-header {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.form-label {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.form-container {
  display: flex;
}
.form-container .form-control {
  margin-bottom: 0px;
  width: 357px;
}
.form-container button {
  margin-left: 16px;
}
.button-container {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
.social-media-link {
  margin-top: 24px;
}
.form-control.dark {
  background: #f7f7f8;
}
.copy-link-form {
  position: relative;
  margin-top: 24px;
}
.copy-link-form span {
  position: absolute;
  top: 29%;
  right: 18px;
  cursor: pointer;
  color: #0781f2;
  font-size: 14px;
  line-height: 130%;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
}
.vc-nominate {
  /* width: 585px; */
  text-align: left;
}
.nominate-msg {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
  margin-top: 48px;
}
.nominate-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 12px;
}
.invite-message-container {
  border-radius: 4px;
  margin-top: 24px;
  padding: 24px;
  text-align: left;

  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
}
.invite-message {
  margin-top: 12px;
}
strong {
  color: #1b1e22 !important;
  font-weight: bolder;
}
font {
  color: #1b1e22 !important;
}
.text-black-color {
  color: #1b1e22 !important;
}
.text-grey-color {
  color: #c8cbd0;
}
.invite-message font,
.check-message,
.invite-message {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.check-message {
  margin-top: 12px;
}
.vc-form-container {
  margin-bottom: 70px;
}
.vc-form-container {
  margin-bottom: 0px;
}
.vc-form-container input {
  margin-bottom: 0;
}
.vc-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.form-error {
  margin-top: 4px;
  height: 16px;
  color: red;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: left;
}
.vc-msg-error {
  color: red;
  font-size: 14px;
}
.edit-pencil {
  display: flex;
}
.referral-icon,
.message-wrapper {
  width: 50%;
  height: 100%;
}
.message-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nominate-container {
  display: flex;
  margin-top: 16px;
  padding: 48px 0px 56px 0px;
  justify-content: center;
  background: #ffffff;
}
.centered-container {
  width: 504px;
  text-align: left;
}
.wrapper-tabs {
  display: flex;
  background: #f7f7f8;
  border: 1px solid #e4e5e7;
  border-radius: 4px;
  width: 504px;
}
.referral-tab {
  padding: 12px 24px;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  cursor: pointer;
}
.founder-container {
  margin-top: 44px;
}
.active-tab {
  color: #0781f2;
  background: #fdfdfd;
  box-shadow: -2px 1px 5px rgba(27, 30, 34, 0.1);
  border-radius: 5px;
}
.founder-profit {
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}
.bottom-text {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 4px;
}
.referral-link-container {
  padding-top: 24px;
}
.vc-details-form .form-control {
  margin-bottom: 0;
}
.vc-details-form {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}
@media (max-width: 1440px) {
  .form-container .form-control {
    width: 357px;
  }
}
@media (max-width: 1280px) {
  .form-container .form-control {
    width: 357px;
  }
}
</style>
